<template>
  <page-container3>
    <a-row :gutter="2" type="flex">
      <a-col v-bind="{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }">
        <page-model-4
          :modalType="scgyfangan.modalType"
          :search="scgyfangan.search"
          :title="scgyfangan.title"
          :column_flag="scgyfangan.column_flag"
          :height2="tableHeight.y"
          :mode="scgyfangan.mode"
          @ok="getFangan"
        />
      </a-col>
      <a-col v-bind="{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }">
        <page-model-4
          :modalType="fangan_list.modalType"
          :search="fangan_list.search"
          :title="fangan_list.title"
          :column_flag="fangan_list.column_flag"
          :height2="tableHeight.y"
          :mode="fangan_list.mode"
        />
      </a-col>
    </a-row>
  </page-container3>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import PageModel4 from '@/components/page-model4/index.vue';
import { debounce } from 'lodash-es';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const current_org = store.getters['user/current_org'];
    const search = reactive({
      current: 1,
      pageSize: 50,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        org_id: current_org.id,
        aim_org_id: current_org.id,
        record: {},
        is_active: true,
        is_search: true,
        group_id: null,
      },
    });
    const tableHeight = reactive({ y: window.innerHeight - 110 });
    const onresize: any = debounce(() => {
      tableHeight.y = window.innerHeight - 110;
    }, 200);
    onMounted(() => {
      window.onresize = onresize;
      onresize();
    });

    const scgyfangan = reactive({
      title: t('部门'),
      column_flag: 'group',
      search: { ...search, requestParams: { ...search.requestParams } },
      modalType: 'permission-group',
      height: tableHeight.y,
      mode: 'radio',
    });

    const fangan_list = reactive({
      title: '',
      column_flag: 'group-user',
      search: { ...search, requestParams: { ...search.requestParams } },
      modalType: 'permission-group-user',
      height: tableHeight.y + 75,
      mode: 'null',
    });

    const getFangan = (record: any) => {
      fangan_list.search.requestParams.record = { ...record };
      fangan_list.search.requestParams.group_id = record.id;
    };
    return {
      scgyfangan,
      fangan_list,
      tableHeight,
      getFangan,
    };
  },
  components: {
    PageModel4,
  },
});
</script>

<style lang="less" scoped>
.title {
  margin-bottom: 16px;
  color: @text-color;
  font-weight: 500;
  font-size: 16px;
}
</style>
